import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { Link } from "gatsby";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>Curs Online acTIC Nivell Mitjà</Title>
                <Text>
                    Et presentem el millor curs per preparar les acTIC nivell mig
                    <br />
                    <br />
                    Un curs amb accés de 90 dies al campus interactiu, amb vídeos explicatius de
                    cada unitat formativa i vídeo-exercicis.
                    <br />
                    <br />
                    Un total de 8 unitats formatives de totes les competències exigides.
                    <br />
                    <br />
                    Estudia des de qualsevol lloc i prepara't amb tot el material que et sortirà el
                    dia de la prova.
                    <br />
                    <br />
                    C1 Cultura, participació i civisme digital
                    <br />
                    <br />
                    Teoria (4 vídeos )…………………………………………20 minuts
                    <br />
                    <br />
                    · Vídeo 1: Tipologies de comunicació, classificació de botigues electròniques,
                    avantatges i desavantatges del comerç electrònic
                    <br />
                    <br />
                    · Vídeo 2: Tràmits virtuals, signatura digital, MUVE, accessibilitat a internet
                    i limitacions
                    <br />
                    <br />
                    · Vídeo 3: Drets fonamentals d'internet, APDCAT, dades personals, propietat
                    intel·lectual, tipologies de llicències
                    <br />
                    <br />
                    · Vídeo 4: IOT, codis QR, mineria de dades
                    <br />
                    <br />
                    Pràctica (6 vídeos) (14-15 exercicis)…………..15 minuts
                    <br />
                    <br />
                    · Vídeo 1: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 2: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 3: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 4: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 5: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 6: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    C2 Tecnologia digital i ús de l'ordinador i del sistema operatiu
                    <br />
                    <br />
                    Teoría (3 vídeos) …………………………………………16 minuts
                    <br />
                    <br />
                    · Vídeo 1:Dispositius wearable, tipologies de dispositius portables, utilitats
                    dels dispositius mòbils
                    <br />
                    <br />
                    · Vídeo 2: Sistemes operatius, principals tipologies de xarxes i la seva
                    història, fibra òptica
                    <br />
                    <br />
                    · Vídeo 3: Compressió d'informació, avantatges i desavantatges, manteniment i
                    rendiment d'ordinadors
                    <br />
                    <br />
                    Pràctica (2 vídeos) (14-15 exercicis)……………10 minuts
                    <br />
                    <br />
                    · Vídeo 1: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 2: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    C3 Navegació i comunicació en el món digital
                    <br />
                    <br />
                    Teoria (3 vídeos)………………………………………….12 minuts
                    <br />
                    <br />
                    · Vídeo 1: Adreces habituals, motors de cerca, navegació privada, acotar cerques
                    <br />
                    <br />
                    · Vídeo 2: Veu IP, missatgeria instantània
                    <br />
                    <br />
                    · Vídeo 3: Programaris maliciosos, cookies, tallafoc
                    <br />
                    <br />
                    Pràctica (2 vídeos) (14-15 exercicis)……………10 minuts
                    <br />
                    <br />
                    · Vídeo 1: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 2: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    C4 Tractament de la informació escrita
                    <br />
                    <br />
                    Teoria (3 vídeos)……………………………………….…12 minuts
                    <br />
                    <br />
                    · Vídeo 1: Tabulacions, tipologies, sagnat, interlineat
                    <br />
                    <br />
                    · Vídeo 2: Creació de taules, utilització de sinònims, inserció d'imatges
                    <br />
                    <br />
                    · Vídeo 3: Estils de paràgraf, assistents de combinació de correu, configuració
                    de la carta model
                    <br />
                    <br />
                    Pràctica (2 vídeos)(14-15 exercicis) ………..…..7 minuts
                    <br />
                    <br />
                    · Vídeo 1: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 2: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    C5 Tractament de la informació gràfica, sonora i de la imatge en moviment
                    <br />
                    <br />
                    Teoria (3 videos)………………………………………….10 minuts
                    <br />
                    <br />
                    · Vídeo 1: Modificació d'imatges, bitmaps, gràfics vectorials
                    <br />
                    <br />
                    · Vídeo 2: Gràfics vectorials i mapes de bits, extensions de formats gràfics,
                    <br />
                    <br />
                    · Vídeo 3: Taxa de bits, equalitzador, formats (NTSC, PAL, SECAM) Pràctica (2
                    vídeos) (14-15 exercicis)……………10 minuts
                    <br />
                    <br />
                    · Vídeo 1: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 2: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    C6 Tractament de la informació numèrica
                    <br />
                    <br />
                    Teoría (3 vídeos)…………………………………………15 minuts
                    <br />
                    <br />
                    · Vídeo 1: Cerques a Excel i característiques de les dades
                    <br />
                    <br />
                    · Vídeo 2: Filtracions de dades, suggeriments de recerca, taules i funcions
                    <br />
                    <br />
                    · Vídeo 3: Referències estables i gràfics
                    <br />
                    <br />
                    Pràctica (2 vídeos) (14-15 exercicis)………..….10 minuts
                    <br />
                    <br />
                    · Vídeo 1: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 2: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    C7 Tractament de les dades
                    <br />
                    <br />
                    Teoría (3 videos)…………………………………………. 11 minuts
                    <br />
                    <br />
                    · Vídeo 1: Bases de dades i exemples, tipologies de filtres
                    <br />
                    <br />
                    · Vídeo 2: Microsoft Access, informe de dades, creació d'informes personalitzats
                    <br />
                    <br />
                    · Vídeo 3: Seguretat i característiques de les dades
                    <br />
                    <br />
                    Pràctica (2 vídeos) (14-15 exercicis)…………… 10 minuts
                    <br />
                    <br />
                    · Vídeo 1: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 2: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    C8 Presentació de continguts
                    <br />
                    <br />
                    Teoría (3 videos)…………………………………………. 10 minuts
                    <br />
                    <br />
                    · Vídeo 1: PowerPoint, panell de dades, icones de vistes, guardar documents
                    <br />
                    <br />
                    · Vídeo 2: Visualització de documents, imprimir documents,
                    <br />
                    <br />
                    · Vídeo 3: Eliminar diapositives, inserir text, inserir imatges, efectes
                    <br />
                    <br />
                    Pràctica (2 vídeos) (14-15 exercicis)……………… 10 minuts
                    <br />
                    <br />
                    · Vídeo 1: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    · Vídeo 2: Completa la frase, omple els buits, relaciona les paraules...
                    <br />
                    <br />
                    Més de 3 hores i 30 minuts de vídeos explicatius.
                    <br />
                    <br />
                    El curs es preparatori per la prova oficial que s'ha de fer demanant dia i hora
                    des de la pàgina de Gencat i presencial.
                    <br />
                    <br />
                    El temps de connexió al curs són 90 dies i dependrà de cada alumne i del temps
                    invertit al curs per assolir tots els coneixements.
                    <br />
                    <br />
                    Per sol's 39.99 euros
                    <br />
                    <br />
                    Matricula't ara i suma punts a la teva oposició.
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/GVPXZv-OAMg "
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
